import { Locale } from '@bemlo/enums'

import { Profession } from '../../generated/graphql'
import { factories } from '../factories'
import { pluralize } from '../utils'

import type { Translation, TranslationBase, TranslationNamespace } from './en'

const IS_JOBS_FEATURE_ENABLED = false

const COMPANY_NAME = 'Bemlo'
const COMPANY_NAME_POSSESSIVE = 'Bemlon'
const SITE_NAME = 'Bemlo'
const SITE_NAME_POSSESSIVE = 'Bemlosta'

const jobs = pluralize('työ', 'työn')
const reviews_ = pluralize('arvostelu', 'arvostelut')
const salaries = pluralize('palkka', 'palkat')

//This is for login functionality. Se bemlo.se/login for reference.
const auth: TranslationNamespace<'auth'> = {
  choose_log_in_method: 'Valitse kirjautumistapa',
  click_link_in_email_to_log_in:
    'Klikkaa sähköpostiviestissä olevaa linkkiä kirjautuaksesi sisään.',
  code: 'Koodi',
  email_address_in_use: 'Sähköpostiosoite on jo käytössä',
  have_not_registered_yet: 'Etkö ole vielä rekisteröitynyt?',
  insert_code_sent_by_sms:
    'Syötä koodi, joka lähetettiin sinulle tekstiviestillä.',
  log_in: 'Kirjaudu sisään',
  log_in_at_our_site: `Kirjaudu sisään ${SITE_NAME}`,
  log_in_description:
    'Katso, mitä työpaikkoja ja yrityksiä olemme löytäneet sinulle sen jälkeen, kun viimeksi kirjauduit sisään.',
  log_in_with_email: 'Kirjaudu sisään sähköpostilla',
  log_in_with_sms: 'Kirjaudu sisään tekstiviestillä',
  no_account_connected_to_email:
    'Tähän sähköpostiosoitteeseen ei ole yhdistetty tiliä',
  no_account_connected_to_phonenumber:
    'Tähän puhelinnumeroon ei ole yhdistetty tiliä',
  phone_number_in_use: 'Puhelinnumero on jo käytössä',
  register: 'Rekisteröidy',
  specify_email_you_used: `Kirjoita sähköpostiosoite, jota käytit rekisteröityessäsi ${SITE_NAME}-sivustolle.`,
  specify_phone_number_you_used: `Anna puhelinnumero, jota käytit, kun rekisteröidyit ${SITE_NAME}-sivustolle.`,
}

const base: TranslationBase = {
  about_us: 'Meistä',
  add: 'Lisää',
  all_departments: 'Kaikki osastot',
  all_fields_must_be_provided: 'Kaikki kentät on täytettävä',
  all_professions: 'Kaikki ammatit',
  all_regions: 'Kaikki alueet',
  all_specializations: 'Kaikki erikoisalat',
  apply_for_job: 'Hae työtä',
  back: 'Takaisin',
  blog: 'Blogi',
  cancel: 'Peruuta',
  collective_agreement: 'Työehtosopimus',
  comment_min_length: (minLength: number) =>
    `Kommentin on oltava vähintään ${minLength} merkkiä`,
  email: 'Sähköposti',
  email_placeholder: 'abc@xyz.fi',
  gathered_best_jobs_for_you_description:
    'Olemme keränneet henkilöstövuokrausalan työpaikat yli 30 henkilöstövuokrausyritykseltä Ruotsista, Norjasta ja Tanskasta, joten voit helposti löytää itsellesi sopivimman työpaikan.',
  invalid_email: 'Sähköpostiosoite ei ole voimassa',
  loading: 'Lataa...',
  log_in: 'Kirjaudu sisään',
  mandatory: 'Pakollinen',
  menu: 'Menu',
  name: 'Nimi',
  name_placeholer: 'Koko nimi',
  no: 'Ei',
  no_collective_agreement: 'Ei työehtosopimusta',
  no_match_found: 'Ei vastaavuutta',
  norway: 'Norja',
  obligatory: 'Pakollinen',
  optional: 'valinnainen',
  other: 'Muu',
  phone_number: 'Puhelinnumero',
  phone_placeholder: '+ 358 50 123 45 67',
  procurements: 'Hankinnat',
  profession: 'Ammatti',
  read_more: 'Lue lisää',
  region: 'Alue',
  remove: 'Poista',
  salary_statistics: 'Palkkatilastot',
  select_profession: 'Valitse ammatti',
  share: 'Jaa',
  show_all: 'Näytä kaikki',
  show_more: 'Näytä lisää',
  staffing_tips: 'Henkilöstövinkit',
  submit: 'Lähetä',
  submitting: 'Lähettää...',
  sweden: 'Ruotsi',
  table_of_contents: 'Sisällysluettelo',
  update: 'Päivitä',
  whats_your_profession: 'Mikä on ammattisi?',
  workers_in: (profession: Profession) =>
    base.$profession(profession, 'plural').toLowerCase(),
  yes: 'Kyllä',
  ...factories(Locale.FI),
}

//bemlo.fi/blog
const blog: TranslationNamespace<'blog'> = {
  blog_heading: 'Artikkeleita terveydenhuollon henkilöstöhallinnosta',
  blog_paragraph_1: `Olemme ${SITE_NAME}:ssa pyrkineet kirjoittamaan artikkeleita, jotka auttavat sairaanhoitajia ja lääkäreitä ymmärtämään paremmin terveydenhuollon henkilöstövuokrausalaa.`,
  blog_paragraph_2: 'Haluaisitko meidän kirjoittavan jostain muusta?',
  comment: 'Kommentoi',
  mail_us_at: 'Mail us at',
  no_blog_posts_yet: 'Blogikirjoituksia ei ole vielä julkaistu.',
  published: 'Julkaistu',
  read_on: (siteName: string) => `Lue lisää ${siteName}`,
  reading_time: (minutes: number) => `${minutes} lukuaika`,
  seo_title: 'Lue artikkeleita terveydenhuollon henkilöstöstä',
  updated: 'Päivitetty',
  write_a_comment: 'Kirjoita kommentti',
}

//bemlo.fi/companies
const companiesList: TranslationNamespace<'companiesList'> = {
  best_for_doctors: 'Parhaat henkilöstövuokrausyritykset lääkäreille',
  best_for_nurses: 'Parhaat henkilöstövuokrausyritykset sairaanhoitajille',
  doctors: 'lääkärit',
  find_best_companies: 'Löydä paras henkilöstövuokrausyritys',
  find_best_companies_for: (profession: string) =>
    `Löydä parhaat henkilöstövuokrausyritykset ${profession}`,
  keywords: ['arvostelut', 'henkilöstövuokrausyritykset'],
  nurses: 'sairaanhoitajat',
  nurses_and_doctors: 'sairaanhoitajat ja lääkärit',
  read_what_others_think: (professions: string) =>
    `Lue, mitä muut ${professions}t ovat sanoneet, ennen kuin valitset uuden henkilöstövuokrausyrityksen. Olemme keränneet kaikki henkilöstövuokrausyritykset ja tehneet parhaan löytämisestä superhelppoa.`,
  see_also: 'Katso myös:',
}

const companyFaq: TranslationNamespace<'companyFaq'> = {
  apply_now: 'Hae nyt',
  does_company_have_collective_agreement: (name: string) =>
    `Onko ${name}:lla työehtosopimus?`,
  how_many_consultants_work_at_company: (name: string) =>
    `Kuinka monta konsulttia työskentelee ${name}?`,
  no_company_does_not_have_collective_agreement: (name: string) =>
    `Ei - ${name}:lla ei ole työehtosopimusta.`,
  number_of_consultants_text: (count: number | null, name: string) =>
    count
      ? `Jos lasket kokopäiväiset konsultit, noin ${count} konsulttia työskentelee kokopäiväisesti ${name}:ssa. ` +
        'Tarkkaa lukumäärää on vaikea määrittää.'
      : 'Meillä ei valitettavasti ole tätä tietoa.',
  read_more: 'Lue lisää',
  yes_company_has_collective_agreement: (name: string) =>
    `Kyllä - ${name}: lla on työehtosopimus!`,
}

const companyPage: TranslationNamespace<'companyPage'> = {
  about_company: (name: string) => `Tietoja ${name}`,
  authorization_description:
    'Almega hyväksyy henkilöstöyritykset, joilla on työehtosopimus ja jotka täyttävät tietyt kriteerit.',
  authorized_collective_agreement: 'Almega on hyväksynyt',
  company_job_postings: (name: string) => `${name} Työpaikkailmoitukset`,
  company_page_description: (name: string) =>
    `Katso, mitä muut ajattelevat työskentelystä ${name}:ssa. Tutustu palkkoihin ja alueisiin, joilla ` +
    `${name} tuotetaan. Hae töihin ${name} suoraan osoitteessa ${SITE_NAME}.`,
  find_jobs: 'Etsi töitä',
  go_back: 'Etusivu',
  go_to_company_list: 'Yritysluetteloon',
  in_region: (region: string) => `in ${region}`,
  jobs: 'Työt',
  new: 'Uusi',
  no_procurements_found_for: (name: string) =>
    `Emme löytäneet yhtään julkista hankintaa ${name}:lta.`,
  other_top_ranked_companies: 'Muut parhaiten sijoittuneet yritykset',
  read_more: 'Lue lisää',
  respond_to_contact_request_from: (name: string) =>
    `Vastaa yhteydenottopyyntöön, jonka on lähettänyt ${name}`,
  reviews: 'Arvostelut',
  salaries: 'Palkat',
  salary_statistics: 'Palkkatilastot',
  see_other_consultant_salaries: (
    profession: Profession,
    regionCount: number,
  ) =>
    `Katso muiden ${base.workers_in(profession)} palkat yli ` +
    `${regionCount} alueilla.`,
  see_user_reported_salaries: (count?: number) =>
    `Katso${count ? ` ${count}` : ''} käyttäjien lisäämät ${salaries(count)}`,
  see_what_others_think_of_company: (name: string) =>
    `Katso, mitä muut ajattelevat ${name}:sta ja vertaile yrityksiä.`,
  summary: 'Yhteenveto',
  the_text_is_taken_from: (source: string) =>
    `Teksti on otettu lähteestä ${source}`,
  the_text_is_taken_from_companys_website: (name: string) =>
    `Teksti on otettu ${name}:n verkkosivuilta`,
  you_and_company_are_in_contact: (name: string) =>
    `Sinä ja ${name} olette yhteydessä`,
}

const companyTable: TranslationNamespace<'companyTable'> = {
  ad: 'Mainos',
  company: 'Yritys',
  find_companies: 'Etsi yrityksiä',
  missing_companies_info: 'Puuttuuko yritys? Lähetä ehdotus osoitteeseen',
  name: 'Nimi',
  number_of_consultants: 'Konsulttien määrä',
  rating: 'Luokitus',
  rating_ascending: 'Luokitus, matalin ensin',
  rating_descending: 'Luokitus, korkein ensin',
  region: 'Alue',
  showing_region: 'Näytä alue',
  sort: 'Lajittelu',
  sort_by: 'Lajittele',
}

const ctaPanel: TranslationNamespace<'ctaPanel'> = {
  compare_companies: 'Vertaile yrityksiä',
  compare_staffing_companies_for: (profession: Profession) =>
    `Vertaa 200+ henkilöstövuokrausyritystä ${base.workers_in(
      profession,
    )}:lle!`,
  easily_send_applications_to_companies:
    'Lähetä hakemukset helposti yrityksille',
  see_information_about_salaries_and_benefits:
    'Katso tietoja palkoista ja eduista',
  see_reviews_from_others_in: (profession: Profession) =>
    `Katso muiden ${base.workers_in(profession)} arvostelut.`,
}

const deleteProfileRedirect: TranslationNamespace<'deleteProfileRedirect'> = {
  we_are_sorry_to_see_you_leaving: 'Olemme pahoillamme, että lähdet Bemlosta!',
  you_are_always_welcome_back: 'Olet aina tervetullut takaisin',
}

const cookieConsent: TranslationNamespace<'cookieConsent'> = {
  accept_all: 'Hyväksy kaikki',
  accept_all_cookies: 'Hyväksy kaikki evästeet',
  accept_selected: 'Hyväksy valitut',
  analytics_cookies: 'Tilastot',
  analytics_cookies_description:
    'Auttaa meitä ymmärtämään, miten sivustoa käytetään, jotta voimme parantaa sitä.',
  choose_cookies: 'Valitse, mitä evästeitä selaimeesi voidaan tallentaa.',
  cookies_description:
    'Käytämme evästeitä parantaaksemme käyttökokemustasi sivustollamme. Joitakin evästeitä käytetään, jotta sivusto toimisi oikein, ja toisia evästeitä käytetään parantamaan käyttökokemustasi.',
  custom_selection: 'Valitse',
  how_we_use_cookies: 'Lue lisää siitä, miten käytämme evästeitä.',
  necessary_cookies: 'Välttämättömät evästeet',
  necessary_cookies_description:
    'Välttämättömiä evästeitä käytetään, jotta verkkosivusto toimisi sujuvasti ja turvallisesti. Mahdollistaa tärkeät toiminnot, kuten kirjautumisen.',
  we_value_your_privacy: 'Yksityisyytesi on meille tärkeää',
}

const fallback: TranslationNamespace<'fallback'> = {
  an_error_occurred: 'Tapahtui virhe',
  to_the_start_page: 'Aloitussivulle',
  were_working_on_a_fix: 'Yritämme korjata asian.',
}

const faq: TranslationNamespace<'faq'> = {
  get_answers_to_your_questions_about_staffing:
    'Get answers to your questions about healthcare staffing',
  no_questions: 'Emme löytäneet kysymyksiä',
  questions: 'Kysymykset',
  subtitle: (email: string) => (
    <>
      Can&apos;t find the answer to your question?
      <br />
      Contact us at{' '}
      <a href={`mailto:${email}`} style={{ color: 'valkoinen' }}>
        {email}
      </a>
      .
    </>
  ),
  title: `Yleiset terveydenhuollon henkilöstökysymykset – ${SITE_NAME}`,
}

const footer: TranslationNamespace<'footer'> = {
  career_cta: 'Kehittäjä? Hae nyt.',
  cookie_policy: 'evästekäytäntö',
}

const header: TranslationNamespace<'header'> = {
  find_a_job: 'Löydä työ',
  home: 'Koti',
  jobs: 'Työpaikat',
  log_in: 'Kirjaudu sisään',
  log_out: 'Kirjaudu ulos',
  my_profile: 'Profiilini',
  salary_calculator: 'Palkkalaskuri',
  see_my_profile: 'Minun profiilini',
  show_the_page_for: 'Näytä sivu',
  switch_language: 'På svenska',
  vardforbundet: 'Ruotsin terveysalan ammattilaisten yhdistys',
}

const iePopup: TranslationNamespace<'iePopup'> = {
  download_chrome_here: 'Lataa Chrome tästä',
  page_does_not_work_with_ie:
    `Valitettavasti ${SITE_NAME} ei toimi hyvin Internet Explorerilla. ` +
    'Suosittelemme vaihtamaan toiseen selaimeen, kuten Chromeen tai Firefoxiin.',
}

// bemlo.fi/jobs is not active, see bemlo.se/jobs for reference
const jobBoard: TranslationNamespace<'jobBoard'> = {
  apply: 'Hae',
  apply_by: 'Hae viimeistään',
  apply_directly_on_website: () => `Hae suoraan ${meta.site_name} sivustolla`,
  bemlo_logotype_alt_text: 'Bemlon logo',
  could_not_find_any_matching_published_vacancies:
    'Ei löytynyt yhtään vastaavaa julkaistua työpaikkaa',
  could_not_find_any_matching_vacancies:
    'Ei löytynyt yhtään vastaavaa avointa työpaikkaa',
  create_a_profile: 'Luo profiili',
  create_a_profile_description:
    'Luo profiili ja saat tarjouksia sopivista avoimista työpaikoista suoraan henkilöstövuokrausyrityksiltä.',
  department_other: 'Osastot',
  distance_work: 'Remote work',
  earliest_job_start: 'Aikaisin aloituspäivä',
  estimated: 'Arvioitu',
  for_the_job: 'työpaikkaan',
  full_time_description: 'Tehtävä on 100%',
  hour_short: 'h',
  invoice_verb: 'Lasku',
  irregular_part_time_description:
    'Tehtävään kuuluu satunnaisia vuoroja silloin tällöin',
  job_board_paragraph:
    'Olemme keränneet avoimia työpaikkoja **30+** henkilöstövuokrausyritykseltä Norjasta, Ruotsista ja Tanskasta. Sairaanhoitajana tai lääkärinä voit helposti löytää itsellesi parhaiten sopivan työpaikan. Pyrimme aina julkaisemaan palkan, jotta voit vertailla korvauksia ja etuja. Löydä seuraava terveydenhuollon työtehtäväsi jo tänään.',
  job_mediated_by_company: (company: string) => `Työn välittäjänä ${company}`,
  job_start: 'Aloituspäivä',
  jobs_page_title: 'Henkilöstöalan työpaikat Skandinaviassa',
  match: 'Osuma',
  month_short: 'kk',
  new: 'Uusi',
  no_description: 'Ei kuvausta',
  profession_title: 'Ammattinimike',
  published_at_date: (date: string) => `Julkaistu ${date}`,
  region: 'Alue',
  region_header_alt_text: 'Kuva alueesta, jossa toimeksianto tapahtuu.',
  regular_part_time_description: 'Tehtävä on 20-80 %.',
  reset_filters: 'Nollaa suodattimet',
  salary: (profession: Profession): string =>
    profession === Profession.DOCTOR ? 'korvaus' : 'palkka',
  show_all_departments: 'Näytä kaikki osastot',
  showing_x_out_of_y_vacancies: (x: number, y: number) =>
    `Näytetään **${x}** **${y}** avoimista työpaikoista`,
  specialization_other: 'Erikoisalat',
  staffing_job_in_region: (region: string) => `Henkilöstötyö ${region}`,
  the_job_is_located_in_municipality: (municipality: string) =>
    `Työpaikka sijaitsee ${municipality}`,
  unknown_date: 'Tuntematon päivämäärä',
  until_further_notice: 'Toistaiseksi',
  vacancies_for_company: (companyName?: string) =>
    `Avoimet työpaikat${companyName ? ` osoitteessa ${companyName}` : ''}`,
  vacancies_for_nurses_and_doctors:
    'Avoimet työpaikat sairaanhoitajille ja lääkäreille Ruotsissa, Norjassa ja Tanskassa',
  week_number: (week: string) => `Viikko ${week}`,
}

const jobPage: TranslationNamespace<'jobPage'> = {
  application_sent: 'Hakemus lähetetty',
  apply_now: 'Hae nyt',
  create_an_account: 'Luo tili',
  create_an_account_description: 'Haluatko tietää enemmän työpaikasta?',
  cta_section_heading:
    'Löydä itsellesi ne vuokratyöpaikat, joista olet aina unelmoinut',
  cta_section_paragraph:
    'Vertaile yrityksiä ja löydä itsellesi sopiva työtehtävä.',
  explore_similar_jobs: 'Tutki vastaavia työpaikkoja',
  open_map: 'Avaa kartta',
  see_more_jobs: 'Katso lisää työpaikkoja',
  see_the_jobs: 'Etsi työpaikkoja',
  your_workplace: 'Työpaikkasi',
}

//bemlo.fi landing page.
const landingPage: TranslationNamespace<'landingPage'> = {
  apply_now: 'Hae nyt',
  find_the_best_staffing_company: 'Löydä täydellinen henkilöstövuokrausyritys',
  seo_description_profession: (profession: Profession) =>
    `Katso kaikki ${base.workers_in(profession)}:n vuokratyöt yhdessä paikassa`,
  ...(IS_JOBS_FEATURE_ENABLED
    ? {
        landing_page_heading_1: 'Löydät parhaat henkilöstöhallinnon työpaikat',
        landing_page_heading_2: 'Norjassa ja Ruotsissa',
        landing_page_paragraph_1: 'Oletko valmiina ansaitsemaasi palkkaan?',
        landing_page_paragraph_2:
          'Vertaile yrityksiä ja löydä itsellesi täydellinen työpaikka',
      }
    : {
        landing_page_heading_1:
          'Löydä parhaat terveydenhuollon henkilöstövuokrausyritykset',
        landing_page_heading_2: 'Norjassa ja Ruotsissa',
        landing_page_paragraph_1:
          'Hae jo tänään, ja anna toimistojen kilpailla puolestasi.',
        landing_page_paragraph_2: '',
      }),
  log_in_at_our_site: `Kirjaudu ${SITE_NAME}`,
  see_jobs: (count: number) => `Katso ${count} ${jobs(count)}`,
  see_the_company_rankings: 'Katso yrityksen luokitukset',
  see_the_jobs: 'Katso työpaikat',
  so_that_you_can_easily_compare_terms_and_salaries:
    'Jotta voit helposti vertailla ehtoja ja palkkoja',
  we_present_staffing_jobs_from_many_companies:
    'Esittelemme henkilöstöhallinnon työpaikkoja monilta yrityksiltä',
}

//www.bemlo.fi/doctors and www.bemlo.fi/nurses
export const landingPage2: TranslationNamespace<'landingPage2'> = {
  apply_now: 'Hae nyt',
  blog_button: 'Lisää artikkeleita',
  blog_heading: 'Elämää vuokratyön parissa',
  blog_paragraph_for_profession: (profession: Profession) =>
    `Me ${SITE_NAME}:ssa haluamme kirjoittaa artikkeleita, jotka auttavat ${base.workers_in(
      profession,
    )} paremmin ymmärtämään terveydenhuollon henkilöstövuokrausalaa.`,
  blog_paragraph_generic: `Me ${SITE_NAME}:ssa haluamme kirjoittaa artikkeleita, jotka auttavat terveydenhuollon työntekijät paremmin ymmärtämään henkilöstövuokrausalaa.`,
  blog_read_more: 'Lue lisää',
  companies_and_many_more: '... ja paljon muuta',
  companies_based_on_reviews: (count: number) =>
    `Perustuu ${count} ${reviews_(count)}`,
  companies_heading: IS_JOBS_FEATURE_ENABLED
    ? 'Yhdistämme sinut parhaiden henkilöstövuokrausyritysten kanssa.'
    : 'Olemme keränneet henkilöstövuokrausyritykset.',
  companies_see_the_companies: 'Katso yritykset',
  create_a_profile: 'Luo profiili',
  cta_button: IS_JOBS_FEATURE_ENABLED ? 'Löydä unelmatyöni' : 'Katso yritykset',
  cta_heading_1: `${
    IS_JOBS_FEATURE_ENABLED ? 'Yhdistetään' : 'Löydä'
  } vuokratyöpaikkoja ulkomailta`,
  cta_heading_2: 'josta olet aina unelmoinut',
  cta_paragraph: 'Vertaile yrityksiä ja löydä itsellesi sopiva työpaikka.',
  explanation_button: 'Aloita 5 minuutissa',
  explanation_heading: `Miten ${SITE_NAME} toimii`,
  explanation_step_1_heading: 'Luo tili',
  explanation_step_1_paragraph: (profession: Profession) =>
    `Luoessasi tilin ${SITE_NAME}, keräät työelämäsi ${base
      .$profession(profession)
      .toLowerCase()} yhteen paikkaan.`,
  explanation_step_1_paragraph_generic: `Luoessasi tilin ${SITE_NAME}, keräät työelämäsi yhteen paikkaan.`,
  explanation_step_2_heading: 'Etsi työpaikka',
  explanation_step_2_paragraph:
    'Pääset käsiksi koko markkinoiden vuokratyöpaikkoihin ja saat mieleisesi työpaikat.',
  explanation_step_3_heading: 'Aloita työskentely',
  explanation_step_3_paragraph:
    'Varmistamme, että saat useita tarjouksia jokaisesta työpaikasta, jotta voit maksimoida palkkasi ilman ylimääräisiä ponnisteluja.',
  intro_button: 'Katso työpaikat',
  intro_clarification: 'Maksutta - aloita 5 minuutissa!',
  ...(IS_JOBS_FEATURE_ENABLED
    ? {
        intro_heading_1: (profession: Profession) =>
          `Working in staffing as a Finnish ${base
            .$profession(profession)
            .toLowerCase()}? Meillä on työpaikat `,
        intro_heading_2: () => '',
        intro_heading_3: () => 'Scandinaviassa.',
        intro_paragraph_1: 'Oletko valmis ansaitsemaasi palkkaan?',
        intro_paragraph_2:
          'Yhdistämme sinut parhaiden työpaikkojen kanssa yli 30 henkilöstövuokrausyritykseltä.',
      }
    : {
        intro_heading_1: (profession: Profession) => (
          <>
            Finnish {base.$profession(profession).toLowerCase()}?<br />
          </>
        ),
        intro_heading_2: (_: Profession) =>
          'Löydä unelmiesi työpaikka Ruotsista, Norjasta tai',
        intro_heading_3: (_: Profession) => `Tanskasta.`,
        intro_paragraph_1: 'Oletko valmis saamaan ansaitsemasi palkan?',
        intro_paragraph_2:
          'Bemlo yhdistää sinut Skandinavian huipputyöpaikkoihin.',
      }),
  are_you_a_care_giver: () => 'Oletko terveydenhuollon laitos?',
  intro_image_alt: (profession: Profession) =>
    `Valokuva hymyilevästä ${base.$profession(profession).toLowerCase()}`,
  jobs_button: (count: number) => `Katso ${count} muita ${jobs(count)}`,
  jobs_heading_for_profession: (profession: string) =>
    `Meillä on työpaikkoja ${profession}:lle`,
  jobs_heading_generic: `Meillä on työpaikkot`,
  jobs_subscribe: `Tilaa työtarjouksia`,
  learn_1_buttonText: 'Laske palkkasi',
  learn_1_paragraph: (profession: Profession) =>
    `Arvioi, kuinka paljon voit tienata ${base
      .$profession(profession)
      .toLowerCase()} valitsemallasi alueella ja osastolla koulutuksesi ja kokemuksesi perusteella.`,
  learn_1_paragraph_generic: `Arvioi, kuinka paljon voit tienata valitsemallasi alueella ja osastolla koulutuksesi ja kokemuksesi perusteella.`,
  learn_1_title: 'Palkkalaskuri',
  learn_2_buttonText: 'Vertaa palkkoja',
  learn_2_paragraph:
    'Katso käyttäjien raportoimia palkkoja kollegoilta, joilla on aiempaa kokemusta vuokratyöstä.',
  learn_2_title: 'Palkkatilastot',
  learn_3_buttonText: 'Artikkeleihin',
  learn_3_paragraph:
    'Löydä vastaukset kaikkiin kysymyksiisi terveydenhuollon henkilöstövuokrauksesta. Miten maksimoit palkkasi? Mikä on hankintamenettely? Ja paljon muuta.',
  learn_3_title: 'Blogimme',
  learn_4_buttonText: 'Katso kaikki hankitut palkat',
  learn_4_paragraph:
    'Katso, mistä yrityksestä voit saada korkeimman palkan. Näytämme sinulle kaikki Ruotsin kunnalliset ja alueelliset haut.',
  learn_4_title: 'Julkiset haut',
  learn_5_buttonText: 'Kysymyksiin',
  learn_5_paragraph:
    'Saat vastaukset yleisimpiin terveydenhuollon henkilöstöhankintoja koskeviin kysymyksiin.',
  learn_5_title: 'Usein kysytyt kysymykset',
  learn_heading: 'Opi vuokratyöalasta',
  product_agency_management: () => 'Henkilöstövuokrauksen hallinta',
  product_ai_scheduling: () => 'AI-aikataulutus',
  product_pools: () => 'Sijaisuuksien hallinta',
  see_bemlos_tools: () =>
    'Tutustu Bemlon markkinoiden johtaviin työvuorojen ja sijaisuuksien hallintatyökaluihin',
}

/**
 * TODO: Add support for using multiple namespaces, and add a "form" namespace.
 */
//bemlo.fi/join
const leads: TranslationNamespace<'leads'> = {
  companyHeading: (name: string) =>
    `Kiinnostaisiko sinua työskennellä ${name}?`,
  education: 'Mikä on koulutuksesi (mukaan lukien erikoistuminen)?',
  education_placeholder: 'Olen erikoistunut...',
  heading:
    'Kun haet Bemlon kautta, saat useita tarjouksia parhaiten sijoittuneilta yrityksiltä',
  interests: 'Minkälaisesta työstä olet kiinnostunut?',
  interests_placeholder: 'Haluan työskennellä...',
  pageTitle: 'Hae henkilöstöhallinnon työpaikkoja 60 sekunnissa',
  paragraph:
    'Täytä tietosi, niin otamme sinuun yhteyttä heti, kun löydämme sopivia vuoroja.',
  phone_number: 'Puhelinnumero',
  profession: 'Mistä ammattiryhmästä etsit työtä?',
  submit: 'Lähetä',
  thank_you_for_submitting:
    'Kiitos kiinnostuksestasi! Otamme sinuun pian yhteyttä.',
  when_to_work: 'Milloin haluat aloittaa ja kuinka kauan haluat työskennellä?',
  when_to_work_placeholder: 'Haluan aloittaa työt...',
  where_to_work: 'Missä maissa ja paikassa (paikoissa) haluat työskennellä?',
  where_to_work_placeholder: 'Haluan työskennellä...',
}

const menuItems: TranslationNamespace<'menuItems'> = {
  about_us: 'Meistä',
  blog: 'Artikkelit',
  companies: 'Toimistojen ranking-luettelo',
  jobs: 'Jobs',
  procurements: 'Haut',
  register: 'Sisällysluettelo',
  salary_calculator: 'Palkkalaskuri',
  salary_statistics: 'Palkkatilastot',
  staffing_tips: 'FAQ',
  vardforbundet: 'Vårdförbundet', //this doesn't need translation
}

const meta: TranslationNamespace<'meta'> = {
  company_name: COMPANY_NAME,
  company_name_possessive: COMPANY_NAME_POSSESSIVE,
  site_description:
    'Vertaile nopeasti ja helposti terveydenhuollon henkilöstövuokrausyrityksiä',
  site_keywords: ['bemlo', 'terveydenhuolto', 'henkilöstö'],
  site_name: SITE_NAME,
  site_name_possessive: SITE_NAME_POSSESSIVE,
}

const notFound: TranslationNamespace<'notFound'> = {
  page_not_found: 'Sivua ei löytynyt',
  to_the_start_page: 'Aloitussivulle',
  you_found_a_page_that_doesnt_exist: 'Löysit sivun, jota ei ole olemassa.',
}

const phone_number: TranslationNamespace<'phone_number'> = {
  enter_valid_number_which_country_code:
    'Syötä kelvollinen puhelinnumero, jossa on maakoodi (+xx).',
  invalid: 'Virheellinen puhelinnumero',
  mandatory_field: 'Puhelinnumero on pakollinen kenttä',
  too_short: 'Puhelinnumero on liian lyhyt',
}

const sentry: TranslationNamespace<'sentry'> = {
  errorFormEntry:
    'Jotkut kentät olivat virheellisiä. Korjaa virheet ja yritä uudelleen.',
  errorGeneric:
    'Tuntematon virhe tapahtui raportin lähettämisen aikana. Yritä uudelleen.',
  labelClose: 'Sulje',
  labelComments: 'Mitä tapahtui?',
  labelEmail: 'Sähköposti',
  labelName: 'Nimi',
  labelSubmit: 'Lähetä',
  subtitle: 'Tiimillemme on ilmoitettu',
  subtitle2: 'Jos haluat auttaa, kerro alla, mitä tapahtui.',
  successMessage: 'Palautteesi on lähetetty. Kiitos!',
  title: 'Näyttää siltä, että meillä on ongelmia.',
}

//se https://www.bemlo.se/companies/viva-bemanning/ for reference
const reviews: TranslationNamespace<'reviews'> = {
  be_the_first_reviewer_of: (name: string) =>
    `Be the first reviewer of ${name} by using the form above.`,
  comments: 'Kommentit',
  confirm: 'Vahvista',
  email: 'Sähköposti',
  email_must_be_valid:
    'Jos sähköpostiosoitteesi on virheellinen, kommenttia ei julkaista.',
  go_back: 'Palaa takaisin',
  guest: 'Vieras',
  have_you_worked_at_company: (name: string) =>
    `Oletko työskennellyt ${name}:ssa?`,
  have_you_worked_at_company_info: () =>
    'Hyväksymme arvostelut vain entisiltä tai nykyisiltä työntekijöiltä.',
  help_others: (name: string) => (
    <>
      Onko sinulla kokemusta työskentelystä <strong>{name}</strong>:ssa? Auta
      muita antamalla arvostelusi!
    </>
  ),
  leave_a_comment: 'Jätä kommentti',
  license_number: 'Valtuutusnumero',
  moderator: 'Moderaattori',
  name: 'Nimi',
  next: 'Seuraava',
  no_reviews_available: 'Ei arvosteluita saatavilla.',
  only_show_reviews_from: 'Näytä vain arvostelut osoitteesta',
  please_leave_a_star_rating: 'Ole hyvä ja jätä tähtiluokitus',
  rate: (name: string) => `Arvioi ${name}`,
  rating: 'Luokitus',
  review: 'Arvostelu',
  review_could_not_be_submitted:
    'Arvosteluasi ei voitu lähettää. Yritä uudelleen',
  review_fraud_warning_subtitle: 'Pyrimme aktiivisesti estämään petoksia.',
  review_fraud_warning_title:
    'Yksi tai useampi arvostelu on poistettu vahvistetun huijauksen vuoksi.',
  review_security_information: (
    <>
      Lähettämällä arvostelusi vahvistat, että:
      <ul>
        <li>Olet työskennellyt yritykselle, jota arvostelet</li>
        <li>Olet lisensoitu sairaanhoitaja tai lääkäri</li>
        <li>Arvostelusi sisältö on 100% totta</li>
      </ul>
      Henkilökohtaisia tietojasi ei <b>koskaan</b> jaeta
      {SITE_NAME} ulkopuolelle.
    </>
  ),
  start_bankid: 'Käynnistä BankID-sovellus',
  start_bankid_paragraph:
    'Käynnistä BankID-sovellus mobiililaitteellasi ja skannaa QR-koodi.',
  terms_and_conditions: 'ehdot',
  thanks_for_sharing_your_experience: 'Kiitos kokemuksen jakamisesta!',
  trust_text:
    `${SITE_NAME} perustuu luottamukseen ja siihen, että annetut arvostelut ovat rehellisiä. ` +
    'Arvostele siis vain yrityksiä, joissa olet työskennellyt - kaikki hyötyvät!',
  we_take_measures_against_manipulation:
    'Ryhdymme aktiivisiin toimenpiteisiin estääkseen luokitusten manipuloinnin.',
  when_you_submit_you_accept_our: 'Lähettämällä ilmoitat hyväksyväsi meidän',
  write_a_review_for: (name: string) =>
    `Kirjoita arvostelu seuraavalle henkilölle: ${name}...`,
  xxx: 'XXX',
  your_information_will_not_be_shared_with: (name: string) =>
    `Tietojasi ei jaeta ${name}:n kanssa.`,
}

const tellMeMore: TranslationNamespace<'tellMeMore'> = {
  email: 'Sähköposti',
  tell_me_more_completed: 'Rekisteröitynyt',
  tell_me_more_cta: 'Kerro minulle lisää!',
  tell_me_more_subtitle: `${SITE_NAME} sivustolla voit helposti vertailla ja saada tarjouksia korkeimmalle sijoittuneilta toimistoilta.`,
  tell_me_more_title: 'Haluatko parempaa palkkaa?',
}

//see bemlo.se/jobb for reference.
const registrationForm: TranslationNamespace<'registrationForm'> = {
  add_profile_information_so_that:
    'Anna yhteystietosi, jotta työnantajat voivat ottaa sinuun yhteyttä ja tarjota sinulle mielenkiintoisia työtarjouksia, jotka sopivat toiveisiisi',
  additional_contacts_more_label: 'Haluan ottaa yhteyttä useampaan yritykseen',
  additional_contacts_more_subtitle: (sitename: string) =>
    `Tahdon ${sitename}:n yhdistävän minut useampiin relevantteihin yrityksiin`,
  additional_contacts_none_label: 'Ei enää yrityksiä',
  additional_contacts_none_subtitle: 'Vain valitsemani yritykset',
  additional_contacts_title: 'Haluatko olla yhteydessä muihin yrityksiin?',
  additional_contacts_url_extension_string: 'lisäkontaktit',
  apply_to: (numberOfSelectedCompanies?: number) =>
    `Hae ${numberOfSelectedCompanies} yrityksiin`,
  biography_alternatives_free_text_placeholder:
    'Etsin tehtäviä, joissa... (vapaa teksti) (valinnainen)',
  biography_title: 'Kuvaile itseäsi lyhyesti.',
  biography_url_extension_string: 'biografia',
  boom: 'Boom!',
  confirm_application: 'Vahvista hakemus',
  contact_details_email_error_format:
    'Ole hyvä ja käytä voimassa olevaa sähköpostiosoitetta',
  contact_details_email_error_obligatory: 'Sähköposti on pakollinen kenttä',
  contact_details_email_suggestion: (email: string) => `Tarkoititko ${email}?`,
  contact_details_email_title: 'Sähköposti',
  contact_details_error_message: 'Syötä etu- ja sukunimesi',
  contact_details_name_title: 'Etu- ja sukunimi',
  contact_details_title: 'Otetaan yhteyttä',
  current_employment_status_subtitle:
    'Mikä näistä kuvaa parhaiten nykyistä työllisyystilannettasi?',
  current_employment_status_subtitle_update:
    'Mikä vaihtoehto kuvaa parhaiten nykyistä työtilannettasi',
  current_employment_status_title:
    'Millainen on nykyinen työllisyystilanteesi?',
  current_employment_status_title_update:
    'Siitä on aikaa, kun viimeksi näimme sinut',
  current_employment_status_url_extension_string: 'today',
  departments_interests_alternatives_box_title: 'Osastot',
  departments_interests_free_text_placeholder:
    'Haluan työskennellä... (vapaa teksti)',
  departments_interests_free_text_title: 'Mikään osasto ei sovi?',
  departments_interests_title: 'Minkälaisesta hoitotyöstä olet kiinnostunut?',
  departments_interests_url_extension_string: 'osasto',
  error_action_back_to: (siteName: string) => `Takaisin ${siteName}`,
  error_action_try_again: 'Yritä uudelleen',
  error_paragraph_1: (email: string) => (
    <>
      Jokin meni pieleen, kun hakemustasi lähetettiin. Ole hyvä ja ota meihin
      yhteyttä{' '}
      <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
        {email}
      </a>{' '}
      ja me autamme sinua.
    </>
  ),
  error_paragraph_2: 'Tiimimme tekee kovasti töitä asian korjaamiseksi',
  error_title: 'Jokin meni pieleen',
  existing_account_subtitle: (siteName: string) =>
    `Sinulla näyttää jo olevan tili ${siteName}-sivustolla.`,
  existing_account_title: (siteName: string) =>
    `Kirjaudu sisään ${siteName}-tilillesi`,
  experience_alternatives_box_title: 'Kokemus',
  experience_free_text_placeholder: 'Olen työskennellyt... (vapaa teksti)',
  experience_free_text_title: 'Ei hyvää vaihtoehtoa?',
  experience_title: 'Kuinka monta vuotta kokemusta sinulla on?',
  experience_url_extension_string: 'kokemus',
  home_municipality_alternatives_box_title: 'Kotikunta',
  home_municipality_box_placeholder: 'Kunta...',
  home_municipality_extension_string: 'kunta',
  home_municipality_free_text_placeholder: ' Asun lähellä...',
  home_municipality_free_text_title: 'Kommentti',
  home_municipality_title: 'Missä asut tällä hetkellä?',
  how_much_work_full_time_description:
    'Olen kiinnostunut kokopäivätyöstä (80 %+)',
  how_much_work_irregular_part_time_description:
    'Olen kiinnostunut epäsäännöllisistä työvuoroista',
  how_much_work_part_time_description:
    'Olen kiinnostunut osa-aikatyöstä (20-80 %)',
  how_much_work_title: 'Kuinka paljon työtä olet kiinnostunut tekemään?',
  how_much_work_url_extension_string: 'laajuus',
  job_types_irregular_parttime_subtitle:
    'Tunti- ja vuorotyö terveydenhuollon tarjoajien palveluksessa.',
  job_types_permanent_subtitle:
    'Vakituinen työsuhde yksityisten tai julkisten terveydenhuollon tarjoajien palveluksessa.',
  job_types_staffing_subtitle:
    'Lyhyemmät tai pidemmät toimeksiannot henkilöstövuokrausyrityksissä',
  job_types_title: 'Minkä tyyppiset työt kiinnostavat sinua?',
  job_types_url_extension_string: 'työn tyypit',
  journal_system_alternatives_box_placeholder: ' Sanelujärjestelmä...',
  journal_system_alternatives_box_title: 'sanelujärjestelmä',
  journal_system_alternatives_free_text_placeholder:
    'Olen työskennellyt... (vapaa teksti)',
  journal_system_alternatives_free_text_title: 'Puuttuuko jokin järjestelmä?',
  journal_system_title: 'Minkä sanelujärjestelmien kanssa olet työskennellyt?',
  journal_system_url_extension_string: 'sanelujärjestelmä',
  loading: 'Lataa',
  move_back: 'Takaisin',
  move_forward: 'Seuraava kysymys',
  norwegian_license_subtitle:
    'Onko sinulla tai oletko hakenut norjalaista lisenssiä (rekisteröity HPR:ssä)',
  norwegian_license_title: 'Norjan lisenssi',
  norwegian_license_url_extension_string: 'Norjalainen lisenssi',
  page_description:
    'Hae lääkäreiden ja sairaanhoitajien parhaisiin henkilöstötehtäviin.',
  page_title: 'Hae henkilöstövuokrausalan työpaikat 60 sekunnissa',
  please_continue_improving_your_profile:
    'Jatka vastaamista kysymyksiin profiilisi täydentämiseksi.',
  please_tell_us_more_about_you: 'Kerro meille lisää sinusta',
  profession_title: 'Mikä on ammattisi?',
  profession_url_extension_string: 'ammatti',
  reference_add_first_reference: 'Lisää ensimmäinen suositus',
  reference_add_reference: 'Lisää suositus',
  reference_add_reference_description:
    'Suosittelemme pyytämään  viimeisimmän esimiehesi ja kaksi kollegaa.',
  reference_added_at: (at: string) => `Lisätty (${at})`,
  reference_button: (hasReferences: boolean) =>
    `Pyydä ${hasReferences ? 'lisää ' : ''}suosituksia`,
  reference_close: 'Sulje',
  reference_entered_at: (at: string) => `Suositus lähti (${at})`,
  reference_extension_string: 'suositus',
  reference_no_references: 'Et ole vielä lisännyt yhtään suositusta.',
  reference_save_and_close: 'Tallenna ja sulje',
  reference_subtitle:
    'Kutsu referenssejä Bemlon kautta, jotta säästät aikaa itsellesi, referensseillesi ja henkilöstövuokrausyrityksille. Referenssisi saavat sähköpostin, jossa on linkki, jonka kautta he voivat nopeasti antaa suosituksen sinusta.',
  reference_title: 'Lisää suositus',
  reference_update_reference: 'Päivitä suositus',
  reference_your_description:
    'Suosituksesi jaetaan automaattisesti niille työnantajille, joiden kanssa olet yhteensopiva.',
  reference_your_title: 'Suosittelusi',
  send_application: 'Lähetä hakemus',
  send_new_application_to: (name?: string) =>
    `Tahdotko lähettää uuden hakemuksen${name ? `${name}` : ''}?`,
  skip_question: 'Ohita kysymys',
  specialization_alternatives_box_title: 'Erikoistuminen',
  specialization_doctor_url_extension_string: 'erikoislääkäri',
  specialization_free_text_placeholder: 'Olen... (vapaa teksti)',
  specialization_free_text_title: 'Eikö koulutustasi ole mainittu?',
  specialization_nurse_url_extension_string: 'erikoistunut sairaanhoitaja',
  specialization_title: 'Mikä on erikoisalasi?',
  success_welcome_message: 'Tervetuloa Bemloon',
  swedish_license_subtitle:
    'Onko sinulla tai oletko hakenut ruotsalaista toimilupaa?',
  swedish_license_title: 'Ruotsalainen lisenssi',
  swedish_license_url_extension_string: 'ruotsalainen toimilupa',
  to_company: (name?: string) => ` ${name ?? 'yritys'}:aan`,
  to_jobs: 'Työpaikoille',
  waiting_for_answers: 'Odotetaan vastauksia',
  we_recommend_picking_1_3_companies: 'Suosittelemme valitsemaan 1-3 yritystä',
  were_handling_your_application:
    'Käsittelemme hakemuksesi mahdollisimman pian.',
  when_to_start_exact_placeholder: 'Valitse päivämäärä',
  when_to_start_exact_title: 'Milloin voit aikaisintaan aloittaa?',
  when_to_start_exact_url_extension_string: ' aikaisintaan',
  which_companies_do_you_want_to_recieve_offers_from:
    'Miltä yrityksiltä haluat vastaanottaa tarjouksia?',
  which_countries_extension_string: 'maa',
  which_countries_title: 'Missä maissa olet kiinnostunut työskentelemään?',
  which_regions_affected_by_karens_alternatives_label: (alternative: string) =>
    `Alue ${alternative}`,
  which_regions_affected_by_karens_box_placeholder: 'alue...',
  which_regions_affected_by_karens_box_title: 'alueet',
  which_regions_affected_by_karens_free_text_placeholder:
    'On niin, että ... (vapaa teksti)',
  which_regions_affected_by_karens_free_text_title: 'Haluatko lisätä jotain?',
  which_regions_affected_by_karens_string: 'karens',
  which_regions_affected_by_karens_title:
    'Oletko työskennellyt jollekin näistä työnantajista viimeisen vuoden aikana?',
  which_regions_to_work_in_alternatives_box_title: 'Alueet',
  which_regions_to_work_in_free_text_placeholder:
    'Voin työskennellä... (vapaa teksti)',
  which_regions_to_work_in_free_text_title: 'Ei sopivaa vaihtoehtoa?',
  which_regions_to_work_in_title: 'Millä alueilla haluat työskennellä?',
  which_work_form_subtitle: 'Miten haluat työskennellä?',
  which_work_form_title: 'Työsuhteen muoto',
  which_work_form_url_extension_string: ' työsuhdelomake',
  your_profession_does_not_match_the_vacancy_profession:
    'Ammattisi ei vastaa työpaikan ammattia',
  youre_application_have_been_sent: 'Hakemuksesi on lähetetty',
  youve_recently_sent_an_application: 'Olet hiljattain lähettänyt hakemuksen',
}

const contactRequest = {
  already_in_contact: 'Olet jo yhteydessä tähän yritykseen',
  contact_request_reject_header: (employerName: string) =>
    `Miksi et halua olla yhteydessä ${employerName}?`,
  contact_request_reject_header_subheader: 'Vastaa vapaaehtoisesti',
  decline: 'Hylkää',
  dislike_company: 'En pidä tästä yrityksestä',
  do_you_want_accept_the_contact_request:
    'Haluatko hyväksyä yhteydenottopyynnön?',
  free_text_extra_comment: 'Koska... (vapaa teksti)',
  free_text_extra_comment_placeholder: 'Koska...',
  reason: 'Syy',
  select: 'Valitse...',
}

const profile: TranslationNamespace<'profile'> = {
  about_you: 'Tietoja sinusta',
  accept: 'Hyväksy',
  add_authorization: 'Lisää valtuudet',
  add_authorization_description: 'Lisää valtuutuksen numero',
  add_authorization_error: 'Anna kelvollinen valtuutusnumero',
  add_authorization_title: '6-9 numeroa',
  answer: 'Vastaus',
  application_is_sent: 'Hakemus on lähetetty',
  apply_cta_subtitle: 'Löydä seuraava unelmatyösi',
  apply_cta_title: 'Valmiina uusiin mahdollisuuksiin?',
  authorization: 'Valtuutus',
  back: 'Takaisin',
  bio: 'Bio',
  close: 'Sulje',
  contacts: 'Yhteystiedot',
  countries: 'Maat',
  current_employee_status: 'Nykyinen työntekijän asema',
  decline: 'Hylkää',
  delete_document: 'Poista asiakirja',
  delete_file: (fileName: string) => 'Poista ' + fileName,
  delete_profile: 'Poista profiili',
  delete_profile_confirmation:
    'Haluatko varmasti poistaa profiilisi? Tämä toiminto on peruuttamaton.',
  delete_profile_description:
    'Poista profiilisi. Tämä toiminto on peruuttamaton.',
  departments: 'Osastot',
  do_you_want_to_upload_this_file: 'Haluatko ladata tämän tiedoston?',
  document_one: 'Documentti',
  document_other: 'Documentit',
  download_document: 'Lataa asiakirja',
  download_file: (fileName: string) => 'Lataa ' + fileName,
  email: 'Sähköposti',
  employers_in_contact_with_you:
    'Työnantajat, joihin olet ollut yhteydessä Bemlon kautta',
  experience: 'Kokemus',
  file_could_not_be_found: (email: string) =>
    'Tiedostoa ei löytynyt. Ota yhteyttä' + email,
  file_is_uploaded: 'Tiedosto on ladattu!',
  file_was_not_accepted: 'Tiedostoa ei hyväksytty, yritä toisella tiedostolla',
  hidden_from_employers: 'Piilotettu työnantajilta',
  home_municipality: 'Kotikunta',
  invite_reference: 'Pyydä suositus',
  is_uploading_file: 'Tiedoston lataaminen',
  jobs: 'Työpaikat',
  journal_systems: 'Sanelusysteemit',
  matching_criteria: 'Vastaavat kriteerit',
  matching_job_emails: 'Täsmäävät työpaikkasähköpostit',
  matching_job_emails_description: 'Vastaanota työtarjouksia nimettömänä',
  my_profile: 'Oma profiilini',
  n_years_of_experience: (years: number) => {
    if (!years) return 'Ei kokemusta'
    return years === 1 ? '1 vuosi kokemusta' : `${years} vuotta kokemusta`
  },
  name: 'Nimi',
  new_contact_requests_from_employers:
    'Uudet yhteydenottopyynnöt työnantajilta',
  no_matching_job_emails: 'Ingen mailing med matchende oppgaver',
  no_matching_jobs: 'Valitettavasti emme löytäneet yhtään sopivaa työpaikkaa',
  no_matching_jobs_description:
    'Päivittämällä mieltymyksiäsi sisällyttämällä niihin useampia alueita ja osastoja, lisäät mahdollisuuksia löytää sopiva työpaikka.',
  norwegian_license: 'Norjalainen lisenssi',
  not_added: 'Ei lisätty',
  once_we_find_a_job:
    'Kun löydämme mieltymyksiisi sopivan työpaikan, lähetämme sinulle sähköpostitse työtarjouksen.',
  open_for_new_opportunities: 'Avoinna uusille mahdollisuuksille',
  overview: 'Yleiskatsaus',
  phone_number: 'Puhelinnumero',
  profession: 'Ammatti',
  reapply: 'Hae uudelleen',
  reapply_explanation:
    'Aloitamme uudelleen hakuprosessin ja etsimme profiiliinne sopivia henkilöstövuokrausyrityksiä.',
  reapply_question: 'Haluatko hakea uudelleen?',
  receive_job_offers: 'Vastaanota työtarjouksia anonyymisti',
  regions: ' Alueet',
  replace_file: (fileName: string) => 'Korvaa ' + fileName,
  replace_with_new_document: 'Korvaa uudella asiakirjalla',
  scope: 'Laajuus',
  see_all_jobs: 'Katso kaikki työpaikat',
  select_files_to_upload: 'Valitse ladattavat tiedostot',
  specialization: 'Erikoistuminen',
  staffing_agencies_contact_you:
    'Henkilöstövuokrausyritykset ottavat sinuun yhteyttä',
  start_date: 'Aloituspäivä',
  swedish_license: 'Ruotsalainen lisenssi',
  the_more_flexible_you_are:
    'Mitä joustavampi olet, sitä suuremmat mahdollisuudet meillä on löytää sinulle sopivia tehtäviä. Helpoin tapa saada työpaikka on, jos olet joustava työmatkojen suhteen.',
  to_your_profile: 'Profiiliisi',
  type_of_document: 'Asiakirjan tyyppi...',
  type_of_employment: 'Työsuhteen tyyppi',
  update_email: 'Päivitä sähköpostiosoite',
  update_name: 'Päivitä nimi',
  update_phone_number: 'Päivitä puhelinnumero',
  update_profile: 'Päivitä profiili',
  upload: 'Lataa',
  upload_cv: 'Lataa CV',
  upload_cv_description: 'Tee profiilistasi erottuva ansioluettelolla',
  upload_document: 'Lataa asiakirja',
  upload_document_description: 'Lataa asiakirja profiiliisi',
  upload_license: 'Lataa lisenssi',
  upload_license_description: 'Lataa lisenssi nopeuttaaksesi prosessia',
  upload_new_file: 'Lataa uusi tiedosto',
  upload_specialization_document: 'Lataa erikoistumisasiakirja',
  upload_specialization_document_description: '',
  uploaded_by_you_on: (date: string) => 'Ladattu ' + date,
  we_have_sent_you_application: (companyName?: string) =>
    `Profiilisi on lähetetty${companyName !== '' ? `${companyName}` : ''}!`,
  we_use_matching_to_find_jobs:
    'Käytämme preferenssejä löytääksemme automaattisesti sinulle sopivia työtehtäviä.',
  what_does_matching_mean: 'Mitä osuma tarkoittaa?',
  what_happens_now: 'Mitä tapahtuu nyt?',
  work_form: 'Työlomake',
  you_are_not_in_contact_with_anyone: 'Et ole yhteydessä kehenkään',
  you_have_selected: (fileName: string) => 'Olet valinnut ' + fileName,
  you_pick_which_companies:
    'Sinä valitset yritykset, joille haluat työskennellä',
  your_application_has_been_sent: 'Hakemuksesi on lähetetty',
}

const fi: Translation = {
  auth,
  base,
  blog,
  companiesList,
  companyFaq,
  companyPage,
  companyTable,
  contactRequest,
  cookieConsent,
  ctaPanel,
  deleteProfileRedirect,
  fallback,
  faq,
  footer,
  header,
  iePopup,
  jobBoard,
  jobPage,
  landingPage,
  landingPage2,
  leads,
  menuItems,
  meta,
  notFound,
  phone_number,
  profile,
  registrationForm,
  reviews,
  sentry,
  tellMeMore,
}

export const locale = Locale.FI

export default fi
